import React, { Fragment, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Chip,
  Breadcrumbs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
	makeStyles,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Moment from "moment";
import MaterialTable from "../../../components/material_table";
import { obtenerItemsProcesos } from "../../adminProcesoEliminacion/requestItem";
import { useHistory } from "react-router-dom";
import {
  ACTUALIZAR_CONTRATO,
  AGREGAR_CONTRATO,
  
  ACTUALIZAR_CONTRATO_BASICO,
} from "../../../constants/permissions";
import { redirecTo } from "../../../services/auth";
import { tienePermisosParaVerTodosContrato } from "../../../services/database/usuario";
import DetalleDeCierre from "./detalle_cierre";
import BarraAvance from "./componentes/BarraAvance";
import HeaderBackTable from "../../../components/HeaderBackTable";

function TablaContratos(props) {
  const {
    usuario,
    contratos,
    dialog_open,
    
    handle_eliminar,
    handle_cancelar,
    handle_export,
    handle_reload,
  } = props;

  const classes = style();
  const history = useHistory();
  const [openDialogoEstatus, setOpenDialogoEstatus] = useState(false);
  const [contratoSelect, setOpenSelect] = useState();

  const permisos = Array.from(usuario.permisos);
  const permisoActualizar = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO
  );
  const permisoAgregar = permisos.find((p) => p.nombre === AGREGAR_CONTRATO);
  

  
  const permisoActualizarBasico = permisos.find(
    (p) => p.nombre === ACTUALIZAR_CONTRATO_BASICO
  );

  

  const [totalEstado, setTotalEstados] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    obtenerItemsProcesos()
      .then((result) => {
        setTotalEstados(result);
        let financiero = [];
        let operaciones = [];
        let comercial = [];
        let personas = [];

        result.map((item) => {
          if (item.grupo === "Financiero") {
            financiero.push(item);
          } else if (item.grupo === "Operaciones") {
            operaciones.push(item);
          } else if (item.grupo === "Comercial") {
            comercial.push(item);
          } else if (item.grupo === "Personas") {
            personas.push(item);
          }
        });
        
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    handle_reload();
  }, [openDialogoEstatus]);

  const handleClickOpenDialog = (idContrato) => {
    console.log("open dialog");
    console.log(idContrato);
    setOpenSelect(idContrato);
    setOpenDialogoEstatus(true);
  };

  const handleClickCloseDialog = () => {
    console.log("close dialog");
    setOpenDialogoEstatus(false);
  };

  const generarEstadoContratoView = (estado) => {
    let color = ["#2ecc71", "#f1c40f", "#e67e22", "#c0392b"];

    let mapEstados = totalEstado.map((item) => {
      let resultMap = {};
      if (estado?.proceso_de_finalizacion) {
        resultMap = estado.proceso_de_finalizacion.find(
          (estado) => estado.id_proceso === item._id
        );
      }

      return {
        ...resultMap,
        ...item,
      };
    });

    //
    let itemObligatorios = mapEstados.filter((item) => item.is_obligatorio);

    let puedeCerrar = itemObligatorios.every((item) => {
      let aux;

      typeof item.is_cerrado === "undefined"
        ? (aux = false)
        : (aux = item.is_cerrado);
      return aux === true;
    });

    let cantFinalizados = mapEstados.filter((item) => item.is_cerrado);
    let per = (cantFinalizados.length * 100) / totalEstado.length;
    let index = 3;
    if (per < 30) {
      index = 3;
    } else if (per >= 31 && per <= 60) {
      index = 2;
    } else if (per >= 61 && per < 100) {
      index = 1;
    } else if (per >= 75) {
      if (puedeCerrar) {
        index = 0;
      } else {
        index = 1;
      }
    }

    return (
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
      >
        <Grid item>
          <BarraAvance
            completed={per.toFixed(1)}
            color={color[index]}
            onClick={() => handleClickOpenDialog(estado)}
          />
          {/* <Chip
            onClick={() => handleClickOpenDialog(estado)}
            label={per.toFixed(1) + " % "}
            style={{ background: color[index], color: "#fff" }}
          /> */}
        </Grid>
      </Grid>
    );
  };

  let columns = [
    {
      title: "Código Proyecto",
      field: "codigo",
      grouping: false,
      cellStyle: {
        fontSize: "0.9em",
        width: "15px",
      },
      render: (row) => generarCodigoContratoView(row.codigo),
    },
    {
      title: "Estado de Cierre",
      field: "codigo",
      grouping: false,
      cellStyle: {
        fontSize: "0.9em",
        width: "15px",
      },
      render: (row) => generarEstadoContratoView(row),
    },
    {
      title: "Gerencia",
      field: "gerencia_ref.sigla",
      cellStyle: {
        width: "7px",
      },
      render: (row) => generarCodigoGerenciaView(row),
    },
    {
      title: "Empresa",
      field: "sociedad_ref.nombre",
    },
    {
      title: "Contrato",
      field: "nombre",
    },
    {
      title: "Incio proceso de Cierre",
      field: "inicio_proceso_de_finalizacion",
      render: (row) => generarFechaView(row, "inicio_proceso_de_finalizacion"),
      defaultSort: "desc",
    },
    {
      title: "Cierre inciado por",
      field: "incio_de_cierre_por",
      render: (row) => getEncargadoCierreView(row),
    },

    {
      title: "Fecha Inicio",
      field: "fecha_inicio",
      render: (row) => generarFechaView(row, "fecha_inicio"),
      hidden: true,
    },
    {
      title: "Fecha Est. Término",
      field: "fecha_termino_estimada",
      render: (row) => generarFechaView(row, "fecha_termino_estimada"),
    },
    {
      title: "Responsable",
      field: "responsable_ref.persona.nombre_completo",
      render: (row) => getEncargadoView(row),
    },
    {
      title: "Responsable Talana",
      field: "responsable_ref.persona.nombre_completo",
      render: (row) => getEncargadoTalanaView(row),
    },
    {
      title: "Cliente",
      field: "mandante_ref.nombre",
      render: (row) => generarMandantesView(row),
    },
  ];

  let actions = [
    (rowData) => ({
      icon: "edit",
      tooltip: "Editar",
      onClick: (event, row) => history.push(`/contrato/${row._id}`),
      iconProps: { style: { color: "#757575" } },
      iconProps: {
        style: {
          color:
            tienePermisosParaVerTodosContrato(usuario) ||
            permisoActualizar ||
            permisoActualizarBasico
              ? "#757575"
              : null,
        },
      },
      disabled:
        !(permisoActualizar || permisoActualizarBasico) &&
        rowData.responsable_ref.persona.usuario_id !== usuario.uid,
    }),

    // {
    //   icon: "edit",
    //   tooltip: "Editar",
    //   onClick: (event, row) => history.push(`/contrato/${row._id}`),
    //   iconProps: { style: { color: "#757575" } },
    //   iconProps: {
    //     style: {
    //       color:
    //         permisoActualizar || permisoActualizarBasico ? "#757575" : null,
    //     },
    //   },
    //   // hidden: !permisoActualizar,
    //   disabled: !(permisoActualizar || permisoActualizarBasico),
    // },

    (rowData) => ({
      icon: "email",
      tooltip: "Encuesta",
      onClick: (event, row) =>
        redirecTo(`https://encuestas.cydingenieria.com/envio_encuestas/${row._id}`),
      iconProps: { style: { color: "#757575" } },
      iconProps: {
        style: {
          color:
            tienePermisosParaVerTodosContrato(usuario) ||
            permisoActualizar ||
            permisoActualizarBasico
              ? "#757575"
              : null,
        },
      },
      disabled: tienePermisosParaVerTodosContrato(usuario)
        ? false
        : !(permisoActualizar || permisoActualizarBasico) &&
          rowData.responsable_ref.persona.usuario_id !== usuario.uid,
    }),
    // {
    //   icon: "email",
    //   tooltip: "Encuesta",
    //   onClick: (event, row) =>
    //     redirecTo(`https://encuestas.cydingenieria.com/envio_encuestas/${row._id}`),
    //   iconProps: { style: { color: "#757575" } },
    //   // hidden: !permisoEnviarEncuesta,
    //   disabled: !permisoEnviarEncuesta,
    // },
    {
      icon: "add",
      tooltip: "Agregar",
      onClick: () => history.push("/contrato"),
      isFreeAction: true,
      // hidden: !permisoAgregar,
      disabled: !permisoAgregar,
    },
  ];

  return (
    <Fragment>
      <main>
        <Container maxWidth="xl" className={classes.main}>
          <Breadcrumbs separator=">">
            <Typography color="textPrimary">Módulo Contratos</Typography>
            <Typography color="textPrimary">
              Contratos en Proceso de Cierre
            </Typography>
          </Breadcrumbs>
          <Container maxWidth="xl">
            <MaterialTable
              title={
                <HeaderBackTable titulo={"Contratos en Proceso de Cierre"} />
              }
              is_loading={!contratos}
              data={contratos}
              columns={columns}
              actions={actions}
              export_function={handle_export}
              fixedColumns={{ right: 0 }}
              exportar={true}
              options={{
                headerStyle: { position: "sticky", top: 0 },
                fixedColumns: {
                  left: 1,
                },
              }}
            />
          </Container>
          <Dialog open={dialog_open} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>Eliminar Contrato</DialogTitle>
            <DialogContent dividers>
              <Typography>
                Se debe confirmar la eliminación del contrato.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handle_eliminar}
                variant="contained"
                color="primary"
              >
                Eliminar
              </Button>
              <Button autoFocus onClick={handle_cancelar} color="primary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
        <DetalleDeCierre
          openDialog={openDialogoEstatus}
          setOpenDialog={setOpenDialogoEstatus}
          onClickCloseDialog={handleClickCloseDialog}
          contrato={contratoSelect}
        />
      </main>
    </Fragment>
  );
}

/**
 * Método encargado de generar el CHIP con el código del contrato/proyecto.
 * @param {*} codigo Código del contrato/proyecto.
 */
export function generarCodigoContratoView(codigo) {
  if (!codigo) {
    return <Chip label={"Sin Código"} color="primary" />;
  } else {
    return <Chip label={codigo} color="primary" />;
  }
}

/**
 * Método encargado de generar el CHIP con la sigla de la gerencia.
 * @param {*} data Datos del contrato.
 */
function generarCodigoGerenciaView(data) {
  let texto = "Sin Gerencia";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.gerencia_ref) {
    texto = data.gerencia_ref.sigla;
  }
  return <Chip label={texto} variant="outlined" color="primary" />;
}

/**
 * Método encargado de generar el CHIP con foto y nombre del encargado.
 * @param {*} data Datos del contrato.
 */
function getEncargadoView(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.responsable_ref) {
    texto = data.responsable_ref.persona.nombre_completo;
    foto = data.responsable_ref.persona.photo;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

function getEncargadoCierreView(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.incio_de_cierre_por) {
    texto = data.incio_de_cierre_por.nombre;
    foto = data.incio_de_cierre_por.photoUrl;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

function getEncargadoTalanaView(data) {
  let texto = "Sin Responsable";
  let foto = "";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.responsable_admin_ref) {
    texto = data.responsable_admin_ref.persona.nombre_completo;
    foto = data.responsable_admin_ref.persona.photo;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

/**
 * Método encargado de formatear las fechas, si corresponde.
 * @param {*} fecha Fecha que será verificada y formateada.
 */
function generarFechaView(data, propiedad) {
  let texto = "Indefinida";
  if (data && typeof data === "string") {
    texto = data;
  } else if (data && Moment(data[propiedad]).year() === 2100) {
    texto = "Indefinido";
  } else if (data && data[propiedad]) {
    texto = Moment(data[propiedad]).format("DD/MM/YYYY");
  }
  return texto;
}

/**
 * Método encargado de generar el CHIP para mandantes.
 * @param {*} data Datos del contrato.
 */
function generarMandantesView(data) {
  let texto = "Sin Gerencia";
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.mandante_ref) {
    texto =
      data.mandante_ref.nombre.length < 25
        ? data.mandante_ref.nombre
        : data.mandante_ref.nombre.substring(0, 25) + "...";
  }
  return <Chip label={texto} variant="outlined" color="primary" />;
}

const style = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export default TablaContratos;
