export const API_PORTAFOLIO = "https://api-portafolio.cydingenieria.com";
export const ADMINISTRACION_USUARIOS = "https://api-usuarios.cydingenieria.com";
export const LOGIN = "https://account.cydingenieria.com";
export const REDIRECT_BASE = "https://api-usuarios.cydingenieria.com/login";
export const CONFIGURADOR = "https://configurador-proyecto.cydingenieria.com";
export const CARTAS = "https://cartas.cydingenieria.com";
export const INCIDENTES = "https://logincidentes.cydingenieria.com";
export const CALIDAD = "https://logcalidad.cydingenieria.com";
export const SDI = "https://logsdi.cydingenieria.com";
export const INSPECCIONES = "https://app-mlp.cydingenieria.com";
export const SGI = "https://sgi.cydingenieria.com";
export const TAREAS = "http://cyd.legav.cl/proyecto";
export const BITACORA = "https://bitacora.cydingenieria.com";
export const LIBRO_OBRA = "https://libro-de-obra.cydingenieria.com";
export const PROYECTOS_GESTOR = "https://proyectos.cydingenieria.com/";
export const ENCUESTAS = "https://encuestas.cydingenieria.com/";
export const STORAGE = "https://storage.cydingenieria.com";