import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";
import Axios from "axios";

const BASE_URL = process.env.REACT_APP_DB_URL;

/**
 * Método encargado de obtener todos los usuarios.
 */
export async function ObtenerUsuarios() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/personas`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}



/**
 * Método encargado de agregar un nuevo usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 */
export async function GuardarUsuario(usuarioPlataforma) {
  try {
    let url = `${BASE_URL}/usuarios`;
    let response = await Axios.post(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}





/**
 * Método encargado de obtener los mandantes.
 */
export async function obtenerUsuariosCyd() {
  try {
    let url = `https://api-usuarios.cydingenieria.com/personas?is_gsuite_talana=true`;
    let response = await Axios.get(url);
    let mandantes = response.data.map(man => {

      return {
        ...man,
        label: `${man.nombre_completo} - ${man.contacto.email}`
      }
    })
    return mandantes;
  } catch (error) {
    console.error(error);
    throw error;
  }
}